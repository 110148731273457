import * as i0 from '@angular/core';
import { Injectable, Inject, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import * as i1$1 from '@angular/router';
import Swal from 'sweetalert2';
import * as i3 from '@ngx-translate/core';
import { from, throwError, BehaviorSubject, of, combineLatest, fromEvent } from 'rxjs';
import { shareReplay, catchError, concatMap, tap, map, mergeMap } from 'rxjs/operators';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { LOCAL_STORAGE } from '@fielddata.io/utils';
import * as i4 from '@fielddata.io/spinners';
import { SpinnerActionTypes } from '@fielddata.io/spinners';
class RoleService {
  constructor(env, options, http) {
    this.env = env;
    this.options = options;
    this.http = http;
  }
  getAvailableRoutes() {
    return this.http.get(`${this.env.portalApiUrl}menu/routesPermission?appParam=${this.options.appKey}`);
  }
  static {
    this.ɵfac = function RoleService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RoleService)(i0.ɵɵinject('env'), i0.ɵɵinject('authOptions'), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RoleService,
      factory: RoleService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RoleService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['env']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['authOptions']
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
class PermissionService {
  constructor(env, options, http) {
    this.env = env;
    this.options = options;
    this.http = http;
  }
  getUserPermissions(projectId) {
    return this.http.get(`${this.env.portalApiUrl}apps/user-permissions/${projectId}/${this.options.appKey}`);
  }
  getGlobalUserPermissions() {
    return this.http.get(`${this.env.portalApiUrl}user-details/rights`);
  }
  static {
    this.ɵfac = function PermissionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PermissionService)(i0.ɵɵinject('env'), i0.ɵɵinject('authOptions'), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PermissionService,
      factory: PermissionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PermissionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['env']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['authOptions']
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
class User {}
class UserService {
  constructor(http, env) {
    this.http = http;
    this.env = env;
    this.baseUrl = this.env.portalApiUrl;
    this.userService = this.baseUrl + 'user-details';
  }
  getUser() {
    const requestUrl = this.userService + '/';
    return this.http.get(requestUrl);
  }
  getUserDetails() {
    return this.user;
  }
  setUser(user) {
    this.user = user;
  }
  static {
    this.ɵfac = function UserService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UserService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject('env'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UserService,
      factory: UserService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['env']
    }]
  }], null);
})();
class AuthService {
  constructor(router, userService, translate, localStorage, env, authConfig) {
    this.router = router;
    this.userService = userService;
    this.translate = translate;
    this.localStorage = localStorage;
    this.env = env;
    this.authConfig = authConfig;
    // Create an observable of Auth0 instance of client
    this.auth0Client$ = from(createAuth0Client({
      domain: this.authConfig.domain,
      clientId: this.authConfig.clientID,
      authorizationParams: {
        redirect_uri: this.authConfig.redirectUri,
        audience: this.authConfig.audience,
        ui_locales: navigator.language.split('-')[0].toString()
        /*overrides: {
          __tenant: this.authConfig.auth0Tenant,
          __token_issuer: this.authConfig.tokenIssuer
        }*/
      }
    })).pipe(shareReplay(1),
    // Every subscription receives the same shared value
    catchError(err => throwError(err)));
    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    this.isAuthenticated$ = this.auth0Client$.pipe(concatMap(client => from(client.isAuthenticated())), tap(res => this.loggedIn = res));
    this.handleRedirectCallback$ = this.auth0Client$.pipe(concatMap(client => from(client.handleRedirectCallback())));
    this.user = new User();
    this.userDetailsNamespace = 'https://userdetails.fielddata.io/user_metadata';
    this.WINDOWS_AZURE_ACTIVE_DIRECTORY = 'waad';
    // Create subject and public observable of user profile data
    this.userProfileSubject$ = new BehaviorSubject(null);
    // Create a local property for login status
    this.loggedIn = null;
    this.redirectURL = '';
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    // this.handleAuthCallback();
  }
  get userProfile$() {
    return this.userProfileSubject$.asObservable();
  }
  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$() {
    return this.auth0Client$.pipe(concatMap(client => from(client.getUser())), tap(user => this.userProfileSubject$.next(user)));
  }
  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
  getTokenSilently$(options) {
    return this.auth0Client$.pipe(concatMap(client => from(client.getTokenSilently(options))));
  }
  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(concatMap(loggedIn => {
      if (loggedIn) {
        // If authenticated, get user and set in app
        // NOTE: you could pass options here if needed
        return this.getUser$();
      }
      // If not authenticated, return stream that emits 'false'
      return of(loggedIn);
    }));
    checkAuth$.subscribe();
  }
  login(redirectPath = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe(client => {
      // Call method to log in
      client.loginWithRedirect({
        appState: {
          target: redirectPath
        },
        authorizationParams: {
          redirect_uri: this.env.callbackURL
        }
      });
    });
  }
  handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    // Call when app reloads after user logs in with Auth0
    if (params.includes('state=')) {
      let targetRoute; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(tap(cbRes => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }, error => {
        this.authError = error.error_description.split(':')[0];
        let title;
        let message;
        let status;
        switch (this.authError) {
          case 'SignUpDone':
            this.translate.get('auth.SignUpDoneTitle').subscribe(translatedTitle => {
              title = translatedTitle;
              message = this.translate.instant('auth.SignUpDoneMessage');
              status = 'success';
              this.showMessage(title, message, status);
            });
            break;
          case 'AccessDenied':
            this.translate.get('auth.AccessDeniedTitle').subscribe(translatedTitle => {
              title = translatedTitle;
              message = this.translate.instant('auth.AccessDeniedMessage');
              status = 'error';
              this.showMessage(title, message, status);
            });
            break;
          case 'EmailVerificationPending':
            this.translate.get('auth.EmailVerificationTitle').subscribe(translatedTitle => {
              title = translatedTitle;
              message = this.translate.instant('auth.EmailVerificationMessage');
              status = 'warning';
              this.showMessage(title, message, status);
            });
            break;
          default:
        }
      }), concatMap(() => {
        // Redirect callback complete; get user and login status
        return combineLatest([this.getUser$(), this.isAuthenticated$]);
      }));
      // Subscribe to authentication completion observable
      // Response will be an array of user, token, and login status
      authComplete$.subscribe(([user]) => {
        // Redirect to target route after callback processing
        if (user['sub'].toString().split('|')[0] === this.WINDOWS_AZURE_ACTIVE_DIRECTORY) {
          this.user.firstName = user['name'].toString().split(',')[1];
          this.user.lastName = user['name'].toString().split(',')[0];
          this.user.salutation = '';
        } else {
          this.user.firstName = user[this.userDetailsNamespace].firstName;
          this.user.lastName = user[this.userDetailsNamespace].lastName;
          this.user.salutation = user[this.userDetailsNamespace].salutation;
        }
        this.user.email = user.email;
        this.user.id = user[this.userDetailsNamespace].portalDb_UserId;
        this.userService.setUser(this.user);
        this.router.navigate([targetRoute]);
      });
    }
  }
  logout() {
    // Remove tokens and expiry time
    if (this.localStorage.getItem('webbgm.user.token.new')) {
      this.localStorage.removeItem('webbgm.user.token.new');
      this.localStorage.removeItem('webbgm.user.token.old');
      this.localStorage.removeItem('endpoint');
    }
    if (this.localStorage.getItem('redirectUrl')) {
      this.redirectURL = this.localStorage.getItem('redirectUrl');
    } else if (this.localStorage.getItem('isBReportFd')) {
      this.redirectURL = this.env.webbgmUrl;
    } else {
      this.redirectURL = this.env.logoutUrl;
    }
    this.localStorage.removeItem('redirectUrl');
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe(client => {
      // Call method to log out
      client.logout({
        clientId: this.env.clientID,
        logoutParams: {
          returnTo: this.redirectURL
        }
      });
    });
  }
  showMessage(title, message, icontype) {
    Swal.fire({
      title,
      html: message,
      text: message,
      customClass: 'width-swal',
      icon: icontype,
      showCancelButton: this.authError === 'EmailVerificationPending',
      confirmButtonText: this.translate.instant('auth.confirmButtonText'),
      cancelButtonText: this.translate.instant('auth.resendVerification'),
      cancelButtonClass: 'swal-new-btn'
    }).then(result => {
      if (result.value) {
        this.logout();
      } else {
        // handle dismissals
        if (this.authError === 'EmailVerificationPending') {
          window.location.href = 'https://' + this.authConfig.domain + '/authorize?audience=https://' + this.authConfig.domain + '/api/v2/&scope=read:current_user&' + 'response_type=code&client_id=' + this.authConfig.clientID + '&' + 'redirect_uri=' + this.env.serverUrl + 'resendVerification&state=resendVerification';
        }
      }
    });
  }
  static {
    this.ɵfac = function AuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthService)(i0.ɵɵinject(i1$1.Router), i0.ɵɵinject(UserService), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(LOCAL_STORAGE), i0.ɵɵinject('env'), i0.ɵɵinject('authConfig'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthService,
      factory: AuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.Router
  }, {
    type: UserService
  }, {
    type: i3.TranslateService
  }, {
    type: Storage,
    decorators: [{
      type: Inject,
      args: [LOCAL_STORAGE]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['env']
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['authConfig']
    }]
  }], null);
})();
class RoleGuard {
  constructor(roleService, router, options) {
    this.roleService = roleService;
    this.router = router;
    this.options = options;
  }
  canActivate(next, state) {
    if (this.allowedRoutes) {
      return this.evalRoutes(state.url);
    } else {
      return this.roleService.getAvailableRoutes().pipe(map(allowedRoutes => {
        this.allowedRoutes = allowedRoutes;
        return this.evalRoutes(state.url);
      }), catchError(() => {
        this.router.navigate([state.url]); // Will try to reload until connection established
        return of(false);
      }));
    }
  }
  evalRoutes(currentRoute) {
    if (this.isRouteAllowed(this.allowedRoutes, currentRoute.split('?')[0])) {
      return true;
    } else {
      this.accessDenied();
      return false;
    }
  }
  isRouteAllowed(allowedRoutes, currentRouteValue) {
    let isValid = false;
    allowedRoutes.forEach(route => {
      if (this.options.routesMap[route] && this.options.routesMap[route] === currentRouteValue) {
        isValid = true;
      }
    });
    return isValid;
  }
  accessDenied() {
    this.router.navigate(['/action/denied']);
  }
  static {
    this.ɵfac = function RoleGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || RoleGuard)(i0.ɵɵinject(RoleService), i0.ɵɵinject(i1$1.Router), i0.ɵɵinject('authOptions'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: RoleGuard,
      factory: RoleGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RoleGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: RoleService
  }, {
    type: i1$1.Router
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['authOptions']
    }]
  }], null);
})();
class PermissionGuard {
  constructor(permissionService, router) {
    this.permissionService = permissionService;
    this.router = router;
  }
  canActivateChild(next) {
    return this.canActivate(next);
  }
  canActivate(next) {
    // Will try to retrieve permissions rules from parent route if it was not specified directly on child route (CanActivateChild)
    const routePermissions = next.data.permissions ? next.data.permissions : next.parent.data.permissions;
    if (typeof routePermissions === 'undefined') {
      this.router.navigate(['/action/denied']);
      return false;
    }
    if (this.userPermissions) {
      return this.hasMatchingPermissions(this.userPermissions, routePermissions);
    } else {
      // needs :projectId somewhere in the route
      return this.permissionService.getUserPermissions(next.paramMap.get('projectId')).pipe(map(userPermissions => {
        this.userPermissions = userPermissions;
        return this.hasMatchingPermissions(this.userPermissions, routePermissions);
      }), catchError(() => {
        this.router.navigate(['/action/denied']);
        return of(false);
      }));
    }
  }
  hasMatchingPermissions(userPermissions, routePermissions) {
    if (userPermissions.some(permission => routePermissions.includes(permission))) {
      return true;
    } else {
      this.router.navigate(['/action/denied']);
      return false;
    }
  }
  static {
    this.ɵfac = function PermissionGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PermissionGuard)(i0.ɵɵinject(PermissionService), i0.ɵɵinject(i1$1.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PermissionGuard,
      factory: PermissionGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PermissionGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: PermissionService
  }, {
    type: i1$1.Router
  }], null);
})();
class ResendVerificationComponent {
  constructor(activatedRoute, auth, route, translate) {
    this.activatedRoute = activatedRoute;
    this.auth = auth;
    this.route = route;
    this.translate = translate;
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const error = params['error'];
      if (error !== undefined && error !== '') {
        Swal.fire({
          title: 'Verification Email Sent',
          customClass: 'width-swal',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: this.translate.instant('alert.confirmButtonText'),
          allowOutsideClick: false
        }).then(result => {
          if (result.value) {
            this.auth.logout();
          } else {
            // handle dismissals
            this.route.navigate(['/']);
          }
        });
      } else {
        this.route.navigate(['/']);
      }
    });
  }
  static {
    this.ɵfac = function ResendVerificationComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ResendVerificationComponent)(i0.ɵɵdirectiveInject(i1$1.ActivatedRoute), i0.ɵɵdirectiveInject(AuthService), i0.ɵɵdirectiveInject(i1$1.Router), i0.ɵɵdirectiveInject(i3.TranslateService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ResendVerificationComponent,
      selectors: [["fielddata-resend-verification"]],
      decls: 0,
      vars: 0,
      template: function ResendVerificationComponent_Template(rf, ctx) {}
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResendVerificationComponent, [{
    type: Component,
    args: [{
      selector: 'fielddata-resend-verification',
      template: ""
    }]
  }], () => [{
    type: i1$1.ActivatedRoute
  }, {
    type: AuthService
  }, {
    type: i1$1.Router
  }, {
    type: i3.TranslateService
  }], null);
})();
class LogoutComponent {
  constructor(auth) {
    this.auth = auth;
  }
  ngOnInit() {
    this.auth.logout();
  }
  static {
    this.ɵfac = function LogoutComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LogoutComponent)(i0.ɵɵdirectiveInject(AuthService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LogoutComponent,
      selectors: [["fielddata-logout"]],
      decls: 0,
      vars: 0,
      template: function LogoutComponent_Template(rf, ctx) {}
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LogoutComponent, [{
    type: Component,
    args: [{
      selector: 'fielddata-logout',
      template: "\r\n"
    }]
  }], () => [{
    type: AuthService
  }], null);
})();
class Webbgm {}
class LoginService {
  constructor(http, localStorage, env) {
    this.http = http;
    this.localStorage = localStorage;
    this.env = env;
    this.baseUrl = this.env.portalApiUrl + 'external-app/';
    this.webbgmToken = new Webbgm();
  }
  setToken(accessToken) {
    this.webbgmToken.accessToken = accessToken;
  }
  getWebbgmToken$(token) {
    if (this.token) {
      return this.token;
    } else {
      const requestUrl = this.baseUrl + this.localStorage.getItem('endpoint') + '?token=' + token;
      this.localStorage.setItem('webbgm.user.token.old', token);
      this.token = this.http.get(requestUrl).pipe(shareReplay());
      return this.token;
    }
  }
  getToken() {
    return this.webbgmToken.accessToken;
  }
  isNewWebbgmUserToken() {
    return this.localStorage.getItem('webbgm.user.token.old') !== this.localStorage.getItem('webbgm.user.token.new');
  }
  isWebbgmTokenValid() {
    if (this.webbgmToken.accessToken !== '') {
      const wdt = new Date(this.webbgmToken.expiresIn).toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      const bdt = new Date().toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      const webbgmTokenExpDt = new Date(wdt);
      const currentBrowserDt = new Date(bdt);
      return webbgmTokenExpDt.getTime() > currentBrowserDt.getTime();
    }
    return false;
  }
  static {
    this.ɵfac = function LoginService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoginService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(LOCAL_STORAGE), i0.ɵɵinject('env'));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoginService,
      factory: LoginService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoginService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: Storage,
    decorators: [{
      type: Inject,
      args: [LOCAL_STORAGE]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['env']
    }]
  }], null);
})();
class InterceptorService {
  constructor(env, localStorage, auth, login, translate, spinner) {
    this.env = env;
    this.localStorage = localStorage;
    this.auth = auth;
    this.login = login;
    this.translate = translate;
    this.spinner = spinner;
    this.wasRedirectAlreadyFired = false;
  }
  intercept(req, next) {
    if (req.url.includes('external-app')) {
      return next.handle(req);
    }
    if (this.localStorage.getItem('webbgm.user.token.new') !== null) {
      if (this.login.isWebbgmTokenValid() && !this.login.isNewWebbgmUserToken()) {
        const token = this.login.getToken();
        const tokenReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(tokenReq);
      }
      this.spinner.pushSpinnerEvent('get-webbgm', SpinnerActionTypes.ADD, {
        backdrop: true,
        translationKeys: ['spinnerMessages.webbgm1', 'spinnerMessages.webbgm2', 'spinnerMessages.webbgm3', 'spinnerMessages.webbgm4', 'spinnerMessages.webbgm5']
      });
      return this.login.getWebbgmToken$(this.localStorage.getItem('webbgm.user.token.new')).pipe(mergeMap(token => {
        if (!req.url.includes('external-app')) {
          this.spinner.pushSpinnerEvent('get-webbgm', SpinnerActionTypes.REMOVE);
          const tokenReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token.accessToken}`
            }
          });
          this.login.setToken(token.accessToken);
          return next.handle(tokenReq);
        }
        return null;
      }), catchError(err => {
        if (req.url.includes('.json')) {
          return next.handle(req);
        }
        this.spinner.pushSpinnerEvent('get-webbgm', SpinnerActionTypes.REMOVE);
        if (err.status === 401) {
          if (err.error.message === 'WEBBGM_AUTHENTICATION_FAILED') {
            this.redirectWebbgmSessionExpired();
          }
          if (err.error.message === 'Unauthorized') {
            window.location.reload();
          }
        } else if (err.status === 403) {
          if (err.error.message === 'WEBBGM_NO_USER') {
            this.redirectWebbmNoUser();
          }
        }
        return of();
      }));
    } else {
      return this.auth.getTokenSilently$().pipe(mergeMap(token => {
        const tokenReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(tokenReq);
      }), catchError(err => {
        // Allow for static resources to go through
        if (req.url.includes('.json')) {
          return next.handle(req);
        }
        return throwError(err);
      }));
    }
  }
  redirectWebbgmSessionExpired() {
    const title = this.translate.instant('webbgm.sessionExpired');
    const message = this.translate.instant('webbgm.redirectionMessage');
    const status = 'warning';
    if (this.wasRedirectAlreadyFired === true) {
      return;
    }
    this.wasRedirectAlreadyFired = true; // Prevent multiple popup windows from firing
    this.showMessageWithSingleButton(title, message, status).then(() => {
      this.clearLocalStorage();
      window.location.href = this.env.webbgmUrl;
    });
  }
  redirectWebbmNoUser() {
    this.clearLocalStorage();
    window.location.href = this.env.homeUrl;
  }
  clearLocalStorage() {
    this.localStorage.removeItem('webbgm.user.token.new');
    this.localStorage.removeItem('webbgm.user.token.old');
    this.localStorage.removeItem('endpoint');
  }
  showMessageWithSingleButton(title, message, icontype) {
    return Swal.fire({
      title,
      html: message,
      text: message,
      customClass: 'width-swal',
      icon: icontype,
      showCancelButton: false,
      confirmButtonText: this.translate.instant('webbgm.confirmButtonText')
    });
  }
  static {
    this.ɵfac = function InterceptorService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || InterceptorService)(i0.ɵɵinject('env'), i0.ɵɵinject(LOCAL_STORAGE), i0.ɵɵinject(AuthService), i0.ɵɵinject(LoginService), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.SpinnerService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: InterceptorService,
      factory: InterceptorService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InterceptorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: ['env']
    }]
  }, {
    type: Storage,
    decorators: [{
      type: Inject,
      args: [LOCAL_STORAGE]
    }]
  }, {
    type: AuthService
  }, {
    type: LoginService
  }, {
    type: i3.TranslateService
  }, {
    type: i4.SpinnerService
  }], null);
})();
class AuthGuard {
  constructor(auth) {
    this.auth = auth;
  }
  canActivateChild(next, state) {
    return this.canActivate(next, state);
  }
  canActivate(next, state) {
    if (localStorage.getItem('webbgm.user.token.old') !== null) {
      return true;
    }
    return this.auth.isAuthenticated$.pipe(tap(loggedIn => {
      if (!loggedIn) {
        this.auth.login(state.url);
      }
    }));
  }
  static {
    this.ɵfac = function AuthGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthGuard)(i0.ɵɵinject(AuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthGuard,
      factory: AuthGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AuthService
  }], null);
})();
const NETWORK_STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline'
};
class NetworkService {
  constructor() {
    this.isNetworkStopped = false;
    this.online = window.navigator.onLine;
    fromEvent(window, NETWORK_STATUS.ONLINE).subscribe(() => {
      this.online = true;
    });
    fromEvent(window, NETWORK_STATUS.OFFLINE).subscribe(() => {
      this.online = false;
    });
  }
  connectionStatus() {
    return this.online ? NETWORK_STATUS.ONLINE : NETWORK_STATUS.OFFLINE;
  }
  static {
    this.ɵfac = function NetworkService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NetworkService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NetworkService,
      factory: NetworkService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NetworkService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class OnlineOnlyAuthGuard {
  constructor(auth, networkService) {
    this.auth = auth;
    this.networkService = networkService;
  }
  canActivate(next, state) {
    if (this.networkService.connectionStatus() === NETWORK_STATUS.ONLINE) {
      return this.auth.isAuthenticated$.pipe(tap(loggedIn => {
        if (!loggedIn) {
          /*Save URL before login again*/
          this.auth.login(state.url);
        }
      }));
    } else {
      return true;
    }
  }
  static {
    this.ɵfac = function OnlineOnlyAuthGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OnlineOnlyAuthGuard)(i0.ɵɵinject(AuthService), i0.ɵɵinject(NetworkService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: OnlineOnlyAuthGuard,
      factory: OnlineOnlyAuthGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OnlineOnlyAuthGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AuthService
  }, {
    type: NetworkService
  }], null);
})();
class CallbackComponent {
  constructor(auth) {
    this.auth = auth;
  }
  ngOnInit() {
    this.auth.handleAuthCallback();
  }
  static {
    this.ɵfac = function CallbackComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CallbackComponent)(i0.ɵɵdirectiveInject(AuthService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CallbackComponent,
      selectors: [["fielddata-callback"]],
      decls: 0,
      vars: 0,
      template: function CallbackComponent_Template(rf, ctx) {},
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CallbackComponent, [{
    type: Component,
    args: [{
      selector: 'fielddata-callback',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "\r\n"
    }]
  }], () => [{
    type: AuthService
  }], null);
})();
class GlobalPermissionGuard {
  constructor(permissionService, router) {
    this.permissionService = permissionService;
    this.router = router;
  }
  canActivateChild(next) {
    return this.canActivate(next);
  }
  canActivate(next) {
    // Will try to retrieve permissions rules from parent route if it was not specified directly on child route (CanActivateChild)
    const routePermissions = next.data.globalPermissions ? next.data.globalPermissions : next.parent.data.globalPermissions;
    let excludeMode = next.data.excludeMode ? next.data.excludeMode : next.parent.data.excludeMode;
    if (typeof excludeMode === 'undefined') {
      excludeMode = false;
    }
    if (typeof routePermissions === 'undefined') {
      this.router.navigate(['/action/denied']);
      return false;
    }
    if (this.userPermissions) {
      return this.matchPermissions(this.userPermissions, routePermissions, excludeMode);
    } else {
      // needs :projectId somewhere in the route
      return this.permissionService.getGlobalUserPermissions().pipe(map(userPermissions => {
        this.userPermissions = userPermissions;
        return this.matchPermissions(this.userPermissions, routePermissions, excludeMode);
      }), catchError(() => {
        this.router.navigate(['/action/denied']);
        return of(false);
      }));
    }
  }
  matchPermissions(userPermissions, routePermissions, excludeMode) {
    if (userPermissions.some(permission => routePermissions.includes(permission)) && !excludeMode || !userPermissions.some(permission => routePermissions.includes(permission)) && excludeMode) {
      return true;
    } else {
      this.router.navigate(['/action/denied']);
      return false;
    }
  }
  static {
    this.ɵfac = function GlobalPermissionGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GlobalPermissionGuard)(i0.ɵɵinject(PermissionService), i0.ɵɵinject(i1$1.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GlobalPermissionGuard,
      factory: GlobalPermissionGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GlobalPermissionGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: PermissionService
  }, {
    type: i1$1.Router
  }], null);
})();
class AuthModule {
  static forRoot(environment, config, options) {
    return {
      ngModule: AuthModule,
      providers: [{
        provide: 'env',
        useValue: environment
      }, {
        provide: 'authConfig',
        useValue: config
      }, {
        provide: 'authOptions',
        useValue: options
      }]
    };
  }
  static {
    this.ɵfac = function AuthModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AuthModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [RoleGuard, PermissionGuard, GlobalPermissionGuard, AuthService, AuthGuard, LoginService, InterceptorService, OnlineOnlyAuthGuard]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthModule, [{
    type: NgModule,
    args: [{
      providers: [RoleGuard, PermissionGuard, GlobalPermissionGuard, AuthService, AuthGuard, LoginService, InterceptorService, OnlineOnlyAuthGuard],
      declarations: [ResendVerificationComponent, LogoutComponent, CallbackComponent],
      imports: [],
      exports: [ResendVerificationComponent, LogoutComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of auth
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthGuard, AuthModule, AuthService, CallbackComponent, GlobalPermissionGuard, InterceptorService, LogoutComponent, OnlineOnlyAuthGuard, PermissionGuard, PermissionService, ResendVerificationComponent, RoleGuard, RoleService };
